import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from '../components/Layout/layout';
import DownloadSection from "../components/DownloadSection";
import ContactForm from "../components/ContactForm";
import PageHero from "../components/PageHero";
import { Seo } from "../components/Seo/seo";
import TextBlockSection from "../components/TextBlockSection";

const TravelerDownloads = () => {
  const data = useStaticQuery(graphql`
    {
    allContentfulPageLayout(
      filter: {id: {eq: "48a79839-6277-5546-a9b1-34f8136221e6"}}
    ) {
      edges {
        node {
          id
          name
          title
          pageDescription
          image {
            url
          }
          content {
            ... on ContentfulPageHero {
              id
              name
              bannerImage {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  width: 1440
                )
              }
              bannerText {
                raw
              }
              smallBannerImage {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  width: 950
                  quality: 100
                )
              }
            }
            ... on ContentfulTextBlock {
              id
              name
              body {
                body
              }
            }
            ... on ContentfulDownloadSection {
              id
              name
              sectionTitle {
                raw
              }
              files {
                filename
                publicUrl
                placeholderUrl
                title
                url
              }
            }
          }
        }
      }
    }
      allContentfulLocation {
        edges {
          node {
            phoneNumber
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <PageHero data={data.allContentfulPageLayout.edges[0].node.content[0]} />
      <TextBlockSection data={data.allContentfulPageLayout.edges[0].node.content[1]}/>
      <DownloadSection data={data.allContentfulPageLayout.edges[0].node.content[2]} />
      <ContactForm data={data.allContentfulLocation.edges[0].node.phoneNumber} />
    </Layout>
  )
}

export const Head = () => (
  <Seo 
    title="Traveler Resources" 
    description="TGL Med Staff provides the highest quality support for its travelers.  Find all the resources and downloads you need as part of the TGL family."
    pathname="/traveler-downloads"
  
  />

)

export default TravelerDownloads

